import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ContactButton from './ContactBtn'

export default function RecentReview() {
  // queries most recent post
    const data = useStaticQuery(
        graphql`
        {
          allDatoCmsReview(sort: {fields: meta___createdAt, order: DESC}, limit: 1) {
            edges {
              node {
                id
                posttitle
                reviewvideo
                jobtype
              }
            }
          }
        }
        
`
    )
  
  let {posttitle, reviewvideo, id, jobtype} = data.allDatoCmsReview.edges[0].node



    return (
        <article>
        <span className='h-1/8 backgroundColor flex flex-wrap p-8'>
          <span className='w-full md:w-1/2 p-4 pl-16 '>
          <h1 style={{fontFamily: "linotype-didot, serif", fontSize: "60px"}}>Working hard, playing hard, resting hard</h1>
            <p className='mb-8' style={{fontFamily: "Josefin sans, sans-serif", fontSize: "20px"}}>At Noble Ocean, we make it our commitment to serve the customer first. This means whatever you need, we will do it. Need a video? Done. Need your room cleaned? We got you. Want your biggest competitor to accidentally wander into traffic? Say no more.</p>
            <ContactButton />
          </span>


          <span className='w-full md:w-1/3 mx-auto py-10 px-10 greyAccent'>
            <div style={{fontFamily: "Josefin sans, sans-serif", marginBottom: "30px", fontWeight: "600", fontSize: "20px"}} className='text-center h4 uppercase'>See what we've been up to:</div>
        <div className="relative video ">
            <iframe className="absolute inset-0 w-full h-full" src={reviewvideo} frameBorder="0" title='Youtube Review Video' key={id} ></iframe>
            </div>
            <p style={{fontFamily: "Josefin sans, sans-serif"}} className=' m-2 opacity-75 text-start text-md sm:text-lg md:text-xl'>{posttitle}</p>
            {/* <p style={{fontFamily: "Josefin sans, sans-serif"}} className=' m-2 opacity-75 text-start text-md sm:text-lg md:text-xl'>{jobtype}</p> */}
        </span>
        </span>
        </article>
    )
}
