import  React, {useState} from 'react'
import { navigate } from "gatsby";

// the contact form is from netlify.  this is a controlled form. 


export default function ContactForm() {
    const [formState, setFormState] = useState({
        name: "",
        email: "",
        message: ""
    })
// state starts as null
    
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }
    // updates state
    const handleSubmit = (e) => {
        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contactForm", ...formState })
      })
        .then(() => navigate("/thanks/"))
        .catch(error => alert(error));

        
      e.preventDefault();
      setFormState({
        name: "",
          email: "",
        message: ""
      })
        
        
    }
    // onsubmit makes post request then navigates to thanks page and resets the form state to null

    return (
        <>
            <section className='justify-start p-6 greyAccent'>
                <h2 style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>We'd love to hear from you!</h2>

                <form className='flex flex-wrap my-2 md:ml-16' style={{maxWidth: "1000px", margin: "auto"}} onSubmit={handleSubmit} name="contactForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="contactForm" />
                    


                    <div className='flex flex-wrap my-3 w-full sm:mx-0 lg:w-full'>
                        <span className='w-full'>
                            <lablel style={{fontFamily: "Josefin sans, sans-serif"}} htmlFor="name">Name
                <input required id='name' className='w-full formColor' name="name" type='text' onChange={handleChange} value={formState.name}></input>
                            </lablel>
                        </span>
                </div>

                    <div className='flex flex-wrap my-3 w-full md:px-0 lg:w-full'>
                    <span className='w-full'>
                            <lablel style={{fontFamily: "Josefin sans, sans-serif"}} htmlFor="email">Email Address
                <input required id='email' className='w-full formColor' name="email" type='text' onChange={handleChange} value={formState.email}></input>
                        </lablel>
                        </span>
                    </div>
                    
                    <div className=' my-2 w-full flex flex-wrap'>
                    <span className='w-full'>
                            <lablel style={{fontFamily: "Josefin sans, sans-serif"}} htmlFor="message">Message
                        <textarea  className='w-full formColor h-24' id='message' name="message" type='text' onChange={handleChange} value={formState.message} />
                        </lablel>
                    </span>
            
                    </div>

                    
                <button id='contact' style={{fontFamily: "Josefin sans, sans-serif", textTransform: "uppercase", color: "#fff", margin: "auto"}} className='p-2 btnColor mt-4' type='submit'>Send Message</button>
            </form>
            </section>
        </>
    )
}
