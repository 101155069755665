import * as React from "react"
import ContactForm from "../components/ContactForm"
// import Carousel from "../components/Carousel"
import Layout from "../components/Layout"
import SuccessStories from "../components/RecentReview"
import { graphql} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Carousel from "../components/Carousel"
import useWindowSize from '../hooks/useGatsbyWindowSize'
import Style from '../styles/styleSheet.css'
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import SEO from "../components/SEO"

const settings = {
  dots: true,
  // lazyLoad: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default function Index({ data }) {
  const { height, width } = useWindowSize()
  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>
      <SEO title='HomePage' description='This is the main page of the website' />
      <figure className='relative  overflow-hidden'>
        {/* <GatsbyImage className='w-100 contrast-100' image={getImage(data.allDatoCmsPageinfo.nodes[0].herobackgroundimage.gatsbyImageData)} /> */}
        <img src="https://www.datocms-assets.com/56195/1633639985-nom-hero.png?bri=-20"></img>
        <div className="absolute w-full py-2.5 bottom-0 inset-x-0  text-white text-xs text-center leading-4 h-full flex items-center justify-center text-2xl sm:text-6xl md:text-8xl lg:text-10xl">
    <span className=''><img src="https://www.datocms-assets.com/56195/1633640927-nom-logo-hero.svg"></img></span>
        {/* <div className="absolute w-full py-2.5 bottom-0 text-8xl inset-x-0  text-white text-xs text-center leading-4">Logo</div> */}
        </div>
      </figure>
      <div>
        <span className='w-1/2 border-6 border-blue-700 p-16'>
        <SuccessStories />
        </span>
        <Slider {...settings}>
            <div style={{padding: "2%"}}><img src="https://www.datocms-assets.com/56195/1633643828-carousel-pic-1.png"></img></div>
            <div>
              <img src="https://www.datocms-assets.com/56195/1633647295-grey-square-edited.png?pad=40"></img>
            </div>
            <div><img src="https://www.datocms-assets.com/56195/1633643928-carousel-pic-2.png"></img></div>
            <div style={{position: ""}}>
              {/* <p style={{position: "absolute", top: "25%", left: "46%", color: "#fff", zIndex: "6000", maxWidth: "300px", fontFamily: "linotype-didot, serif", fontSize: "25px"}}>"Hiring Noble Ocean was a really good idea. Also the head videographer is pretty sexy."</p>
              <p style={{position: "absolute", top: "65%", left: "46%", color: "#fff", zIndex: "6000", maxWidth: "300px", fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>&mdash;Meara C.</p>
              
              <p style={{position: "absolute", top: "20%", left: "26%", color: "#fff", zIndex: "6000", maxWidth: "300px", fontFamily: "linotype-didot, serif", fontSize: "25px"}}>“Noble Ocean was so great to work with. They executed my vision so well. I couldn’t be happier with the results.” </p>
              <p style={{position: "absolute", top: "70%", left: "26%", color: "#fff", zIndex: "6000", maxWidth: "300px", fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>&mdash;John Smith</p> */}

              <img src="https://www.datocms-assets.com/56195/1633647295-grey-square-edited.png?pad=40"></img>
            </div>
            <div><img src="https://www.datocms-assets.com/56195/1633643828-carousel-pic-1.png"></img></div>
            <div>
              <img src="https://www.datocms-assets.com/56195/1633647295-grey-square-edited.png?pad=40"></img>
            </div>
            <div><img src="https://www.datocms-assets.com/56195/1633643928-carousel-pic-2.png"></img></div>
            <div>
              <img src="https://www.datocms-assets.com/56195/1633647295-grey-square-edited.png?pad=40"></img>
            </div>
        </Slider>
        <ContactForm/>
        {/* <section>
          <iframe src="https://forms.monday.com/forms/embed/f266e23043e513d42423af321e43e6ce?r=use1" width="100%" height="1000px" style={{border: "0", boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
        </section> */}
    </div>
    </Layout>
  )
}

export const query = graphql`
{
  allDatoCmsPageinfo {
    nodes {
      herobackgroundimage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      herosectiontext
    }
  }
}
    `
